import React from 'react'
import seedrandom from 'seedrandom'
import _ from 'lodash'
import {
  CSSTransition,
  TransitionGroup
} from 'react-transition-group'

const definedImages = [
  'alberto', 'bar', 'edgar', 'hunds', 'k', 'lana', 'lisa', 'marvin', 'medicine', 'meredith', 'rich', 'to stab', 'union'
]

const wordsToIgnore = [
  'meaningless', 'nothing'
]

const dimensions = {
  width: 640,
  height: 240
}

const circleMaxRadius = 50
const circleMinRadius = 10

const resolution = 10

function ClampToResolution (x) {
  return Math.floor(x / resolution) * resolution
}

function Circle (x, y, r, color) {
  return (
    <circle
      cx={ClampToResolution(x)}
      cy={ClampToResolution(y)}
      r={ClampToResolution(r)}
      fill={color}
    />
  )
}

function Rectangle (x, y, width, height, fill) {
  return (
    <rect
      x={ClampToResolution(x - width / 2)}
      y={ClampToResolution(y - height / 2)}
      width={ClampToResolution(width)}
      height={ClampToResolution(height)}
      fill={fill}
    />
  )
}

function RngElement (rng, array) {
  return array[Math.floor(array.length * rng())]
}

function RandomFillRect (rng) {
  return RngElement(rng, [
    // Preto
    '#444444',

    // Amarelo
    '#ffff00',
    '#ffff00',
    '#ffff00',
    '#ffff00',
    '#ffff00',

    // Branco
    '#ffffff',
    '#ffffff',
    '#ffffff',
    '#ffffff',

    // Pattern
    'url(#Pattern)',
    'url(#Pattern)'
  ])
}

function RandomFillCircle (rng) {
  return RngElement(rng, [
    // Preto
    '#444444',

    // Amarelo
    '#ffff00',
    '#ffff00',
    '#ffff00',
    '#ffff00',
    '#ffff00',
    '#ffff00',

    // Branco
    '#ffffff',
    '#ffffff',
    '#ffffff',
    '#ffffff',

    // Pattern
    'url(#Pattern)',
    'url(#Pattern)',
    'url(#Pattern)'
  ])
}

const RandomizedShapes = {
  // Circle
  circle: (rng) => Circle(
    rng() * dimensions.width,
    rng() * dimensions.height,
    circleMinRadius + rng() * (circleMaxRadius - circleMinRadius),
    RandomFillCircle(rng)
  ),

  flatRect: (rng) => Rectangle(
    rng() * dimensions.width,
    rng() * dimensions.height,
    rngRange(rng, 20, 40),
    rngRange(rng, 100, 300),
    RandomFillRect(rng)
  ),

  upRect: (rng) => Rectangle(
    rng() * dimensions.width,
    rng() * dimensions.height,
    rngRange(rng, 100, 300),
    rngRange(rng, 20, 40),
    RandomFillRect(rng)
  )
}

const RandomizedShapesPossibilities = _.keys(RandomizedShapes)

function rngRange (rng, min, max) {
  return min + rng() * (max - min)
}

/*
 function rngMultipleRanges (rng, ...ranges) {
  const sizes =
  _.map(ranges, range => _.first(range) + _.last(range))
  const concatenated = _.zip(sizes, _.tail(sizes), _.add)
  const totalSize = _.sum(sizes)

  const random = rng() * totalSize
  const index = _.findIndex(concatenated, (conc) => conc > random)
}
*/

function Illustration (props) {
  const composition = _.map(
    _.orderBy(_.filter(props.words, w => _.indexOf(wordsToIgnore, w.word.toLowerCase()) < 0), w => -w.index),
    (wordU) => {
      const i = wordU.index
      var rng = seedrandom(i + 1)

      const size = rngRange(rng, 120, 300)
      const x = rng() * dimensions.width - size / 2
      const y = rng() * dimensions.height - size / 2
      let word = wordU.word.toLowerCase()

      const shapeName = RandomizedShapesPossibilities[Math.floor(rng() * RandomizedShapesPossibilities.length)]
      const shape = RandomizedShapes[shapeName]

      // HACK: for k.
      if (word === 'k.') { word = 'k' }

      const image = (
        <image
          href={`Illustrations/${word}/${word}.png`}
          x={x}
          y={y}
          height={size}
          width={size}
        />
      )

      return (
        <CSSTransition
          key={i}
          classNames='illustration'
          timeout={400}
        >
          <g>
            {
              _.indexOf(definedImages, word) >= 0
                ? image
                : shape(rng)
            }
          </g>
        </CSSTransition>
      )
    }
  )

  return (
    <svg
      className='illustration'
      viewBox={`0 0 ${dimensions.width} ${dimensions.height}`} preserveAspectRatio='xMinYMin'
    >
      <pattern id='Pattern' viewBox='0,0,10,10' width='10' height='10' patternUnits='userSpaceOnUse'>
        <circle cx='2.5' cy='2.5' r='1.5' fill='#444444' />
        <circle cx='7.5' cy='7.5' r='1.5' fill='#444444' />
      </pattern>
      <TransitionGroup component='g'>
        {composition}
      </TransitionGroup>
      Sorry, your browser does not support SVG.
    </svg>
  )
}

export default Illustration
