import React from 'react'
import _ from 'lodash'
import ExploreMeanings from './utils/ExploreMeanings'

const LOG_CSV = false

function YarnDownloader (props) {
  // Create fixed yarn
  // Fix positions
  const center = _.reduce(
    props.positions,
    (acc, pos) => ({
      x: Math.min(acc.x, pos.x),
      y: Math.min(acc.y, pos.y)
    }),
    { x: 9999999, y: 9999999 })

  console.log(center)

  const centralizedPositions = _.mapValues(
    props.positions,
    position => ({
      x: position.x - center.x,
      y: position.y - center.y
    }))

  console.log('downloader positions', props.positions)
  console.log('downloader center', center)
  console.log('downloader centralizedPositions', centralizedPositions)

  const farthest = _.reduce(
    centralizedPositions,
    (acc, pos) => ({
      x: Math.abs(pos.x) > Math.abs(acc.x) ? pos.x : acc.x,
      y: Math.abs(pos.y) > Math.abs(acc.y) ? pos.y : acc.y
    }),
    { x: 0, y: 0 })

  const goodWords = _.filter(props.allWords, word => _.indexOf(_.keys(props.conversions), word.toLowerCase()) < 0)

  console.log('farthest word', farthest)
  console.log(
    'downloader',
    props.allWords,
    _.keys(props.conversions),
    _.filter(props.allWords, word => _.indexOf(_.keys(props.conversions))))
  console.log('good words', goodWords)
  console.log('meanings', props.meanings)

  // TODO: remove/optmize
  // TODO: use actual getmeaning
  const graph =
  _.fromPairs(
    _.map(_.filter(goodWords, w => !_.isUndefined(props.meanings[w])),
      (word) =>
        [word, props.meanings[word]
          .trim()
          .split(' ')
          .map(word => {
            const lower = word.toLowerCase()
            const conversion = props.conversions[lower]
            if (!_.isEmpty(conversion)) return `${conversion}`
            return `${lower}`
          })
        ]
    ))

  console.log('graph', graph)

  function getMeaning (word) {
    return graph[word]
  }

  const distancesToNothing = ExploreMeanings(getMeaning, ['nothing'])
  const orderedDistances = _.map(_.orderBy(_.keys(distancesToNothing), (s) => distancesToNothing[s]), (k) => [k, distancesToNothing[k]])

  // TODO: FIX HERE
  const countPerSize = _.reduce(orderedDistances, (acc, kvp) => {
    const v = kvp[1]
    if (_.isUndefined(acc[v])) {
      acc[v] = 0
    }

    acc[v]++
    return acc
  }, {})

  console.log('distances', distancesToNothing)
  console.log('sorted distances', orderedDistances)
  console.log('count', countPerSize)

  const correctYarn = _.map(
    orderedDistances,
    (orderedDistance, i) => {
      const word = orderedDistance[0]
      const distanceToNothing = orderedDistance[1]
      const title = word

      const tags = props.tags[word] || ''

      let body = getMeaning(word) || ''
      if (!_.isEmpty(body)) {
        const connections = body
          .map(word => {
            const lower = word.toLowerCase()
            const conversion = props.conversions[lower]
            if (!_.isEmpty(conversion)) return `[[${lower}|${conversion}]]`
            return `[[${lower}]]`
          })
          .reduce((acc, connection) => `${acc}${connection}\n`, '\n')
        body = `${body}\n${connections}`
      }

      const radius = distanceToNothing * 500
      const angle = (i % countPerSize[distanceToNothing]) * 2 * Math.PI / countPerSize[distanceToNothing]

      const position = centralizedPositions[word] || {
        x: radius * Math.cos(angle),
        y: radius * Math.sin(angle)
      }

      return {
        title,
        tags,
        body,
        position
      }
    })

  const csvNodes = _.reduce(
    orderedDistances,
    (acc, orderedDistance) =>
      `${acc}\n${orderedDistance[0]},${props.meanings[orderedDistance[0]]}`,
    'id, label'
  )

  if (LOG_CSV) {
    const csvEdges = _.reduce(
      orderedDistances,
      (acc, orderedDistance) => {
        const source = orderedDistance[0]

        const body = getMeaning(source) || ''
        if (!_.isEmpty(body)) {
          return acc + '\n' + body
            .map(word => {
              const lower = word.toLowerCase()
              const conversion = props.conversions[lower]
              if (!_.isEmpty(conversion)) return conversion
              return lower
            })
            .reduce((innerAcc, target) => `${innerAcc}\n${source},${target}`, '')
        } else {
          return acc
        }
      },
      'source, target')

    console.log('correct', correctYarn)

    console.log('csv nodes', csvNodes)
    console.log('csv edges', csvEdges)
  }

  const correctYarnJson = JSON.stringify(correctYarn, null, '\t')

  function handleCopy (event) {
    event.preventDefault()
    navigator.clipboard.writeText(correctYarnJson)
  }

  return (
    <div>
      <div>
            Texto arrumado do yarn
      </div>
      <textarea value={correctYarnJson} readOnly />
      <button onClick={handleCopy}>Copy</button>
    </div>
  )
}

export default YarnDownloader
