import _ from 'lodash'

function ExploreMeaningsRecursive (getWordMeaning, queue, depth, explored) {
  // console.log('exp', explored)
  if (queue.length === 0) return explored
  const word = queue.shift()
  // console.log(word, depth)
  const words = getWordMeaning(word)
  if (_.isUndefined(words)) { console.log('over no word'); ExploreMeaningsRecursive(getWordMeaning, queue, depth + 1, explored) }
  const newWords = _.filter(words, (w) => !_.includes(_.keys(explored), w))
  // console.log(' new', newWords)
  queue.push(...newWords)
  const moreExplored = _.assignWith(explored, _.fromPairs(_.map(words, w => [w, explored[word] + 1])), (obj, src) => obj < src ? obj : src)
  // console.log('exp m', moreExplored)
  return ExploreMeaningsRecursive(getWordMeaning, queue, depth + 1, moreExplored)
}

export default (getWordMeaning, firstWord) => ExploreMeaningsRecursive(getWordMeaning, firstWord, 1, _.set({}, firstWord, 0))
