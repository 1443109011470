import React from 'react'
import _ from 'lodash'
import {
  CSSTransition,
  TransitionGroup
} from 'react-transition-group'

class SavedWords extends React.Component {
  constructor (props) {
    super(props)
    this.state = { opened: false }
    this.handleOpen = this.handleOpen.bind(this)
    this.handleClose = this.handleClose.bind(this)
  }

  handleOpen () {
    this.setState({ opened: true })
  }

  handleClose () {
    this.setState({ opened: false })
  }

  render () {
    const savedWords = _.map(this.props.savedWords, (history, word) => (
      <div key={`saved_${word}`}>
        <span className='saved-words-word'>{word}</span>
        <div className='saved-words-description'>{this.props.getWordMeaning(word)}</div>
        <button className='btn-primary' onClick={() => this.props.onLoadText(history)}>Load</button>
        <button className='btn-secondary' onClick={() => this.props.onRemoveSavedWord(word)}>Remove</button>
        <hr />
      </div>
    ))

    const title = `Saved Words (${savedWords.length})`

    const SavedWordsList = (
      <CSSTransition
        key='savedwords'
        classNames='saved-words'
        timeout={500}
      >
        <div className='saved-words-list'>
          <span className='saved-words-close-btn' onClick={this.handleClose}> &times; </span>
          <h2 className='saved-words-title'>{title}</h2>
          {this.state.opened}
          {savedWords}
        </div>
      </CSSTransition>
    )

    const OpenButton = (
      <span onClick={this.handleOpen} className='saved-words-open-btn'>
        <i className='fa fa-bars' /> {title}
      </span>
    )

    return (
      <div className='saved-words-container'>
        <TransitionGroup>
          {this.state.opened && SavedWordsList}
        </TransitionGroup>
        <CSSTransition
          in={savedWords.length > 0}
          classNames='illustration'
          unmountOnExit
          timeout={400}
        >
          {OpenButton}
        </CSSTransition>
      </div>
    )
  }
}

export default SavedWords
