import React from 'react'
import _ from 'lodash'

class GameWord extends React.Component {
  constructor (props) {
    super(props)
    this.state = { fresh: props.fresh || true, hover: false }
    this.handleMouseEnter = this.handleMouseEnter.bind(this)
    this.handleMouseLeave = this.handleMouseLeave.bind(this)
  }

  handleMouseLeave () {
    this.setState({ fresh: false, hover: false })
  }

  handleMouseEnter () {
    this.setState({ fresh: false, hover: true })
  }

  render () {
    return (
      <span
        onMouseEnter={this.handleMouseEnter}
        onMouseLeave={this.handleMouseLeave}
      >
        <span className='tooltip'>
          <span
            onClick={this.props.handleClick}
            className={(this.props.noInitialHighlight && this.state.fresh) ? ''
              : this.props.isSaved ? 'already-saved'
                : this.state.fresh ? 'not-saved-fresh'
                  : this.state.hover ? 'not-saved-add-highlight' : 'not-saved'}
          >
            {`${this.props.word}`}
          </span>
          &nbsp;
          {this.state.hover && (this.props.isSaved ? (
            <span
              className='tooltiptext tooltip-button'
              onClick={(e) => { e.stopPropagation(); this.props.handleRemoveSaveWord() }}
            >
              Forget Word
            </span>
          ) : (
            <span
              className='tooltiptext tooltip-button'
              onClick={(e) => { e.stopPropagation(); this.props.handleSaveWord() }}
            >
              Save Word
            </span>
          ))}
        </span>
        {this.state.hover && (
          <span className='meaning-preview hover--on'>
            {`${this.props.meaning || 'meaningless'}`} &nbsp;
          </span>)}
      </span>
    )
  }
}

function GameText (props) {
  const text = props.currentText.map((wordObj, i) => {
    return (
      <span className='game-word' key={`word_${wordObj.index}`}>
        <GameWord
          noInitialHighlight={wordObj.index < 0}
          word={wordObj.word}
          handleClick={props.changeWord(i)}
          meaning={props.getWordMeaning(wordObj.word)}
          handleSaveWord={() => props.saveWord(wordObj.word)}
          handleRemoveSaveWord={() => props.removeSaveWord(wordObj.word)}
          isSaved={_.indexOf(_.map(_.keys(props.savedWords), props.getWordConversion), wordObj.word.toLowerCase()) >= 0}
        />
      </span>
    )
  })

  return (
    <h1>
      {text}
    </h1>
  )
}

export default GameText
