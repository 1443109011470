import React from 'react'
import _ from 'lodash'

class YarnUploader extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      value: ''
    }

    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleChange = this.handleChange.bind(this)
  }

  handleChange (event) {
    this.setState({ value: event.target.value })
  }

  handleSubmit (event) {
    event.preventDefault()
    const yarnText = _.cloneDeep(this.state.value)
    const yarnValues = JSON.parse(yarnText)
    // Update meanings
    const meanings = _.fromPairs(
      _.filter(
        _.sortBy(
          _.map(yarnValues, (t, i) =>
            [t.title.toLowerCase(), t.body.trim().split('\n')[0]]
          ), m => m[0]),
        (meaning) => !_.isEmpty(meaning[1])))

    const conversions =
        _.fromPairs(_.sortBy(_.uniq(_.flatten(
          yarnValues.map((t, i) =>
            _.filter(
              t.body.trim().toLowerCase().split('\n').slice(1).map(
                (connection) => {
                  const barPos = connection.indexOf('|')
                  if (barPos < 0) return ['', '']
                  const convertee = connection.slice(2, barPos)
                  const conversion = connection.slice(barPos + 1, connection.length - 2)
                  console.log(connection, convertee, conversion, barPos)
                  return [convertee, conversion]
                }),
              (conversion) => conversion[0] !== conversion[1])
          ))), c => c[0]))

    const tags = _.fromPairs(
      _.filter(
        _.sortBy(yarnValues.map(
          t => [t.title.toLowerCase(), t.tags.trim()])), m => m[0]),
      tag => !_.isUndefined(meanings[tag[0]]))

    const positions = _.fromPairs(_.map(yarnValues,
      v => [v.title.toLowerCase(), v.position]))

    this.props.updateMeanings(meanings, conversions, tags, positions)

    console.log('new meanings: ', meanings)
    console.log('new conversions: ', conversions)
    console.log('new tags: ', tags)
    console.log('new positions: ', positions)
  }

  render () {
    return (
      <div>
        <label>
          <div>
            Colar o texto do yarn json aqui:
          </div>
          <textarea value={this.state.value} onChange={this.handleChange} />

        </label>
        <button onClick={this.handleSubmit}>Submit</button>
      </div>
    )
  }
}
export default YarnUploader
