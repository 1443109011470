import React from 'react'
import _ from 'lodash'

function DictionaryViewElement (props) {
  return (
    <div className='dictionary-element' style={{ display: 'flex', flexWrap: 'no-wrap' }}>
      <div>
        {props.index}
      </div>
      <div className='dictionary-element-edit'>
        <input type='text' style={{ width: '100%' }} value={props.value} onChange={props.handleChange} />
      </div>
      <div>
        <button onClick={props.handleRemoveElement}> &times; </button>
      </div>
    </div>
  )
}

function DictionaryView (props) {
  const mappings = !props.emptyFirst
    ? props.mappings
    : _.fromPairs(_.map(
      _.orderBy(_.toPairs(props.mappings), p => !_.isEmpty(p[1]))
    ))

  if (props.emptyFirst) {
    console.log('dictionary empty', props.mappings, _.orderBy(_.keys(props.mappings), k => props.mappings[k].length))
  }

  const mappingElements = _.map(mappings, (value, key) => (
    <DictionaryViewElement
      key={`${props.title}_${key}`}
      index={key}
      value={value}
      className='ssss open-btn'
      handleChange={props.handleChange(key)}
      handleRemoveElement={() => props.handleRemoveElement(key)}
    />
  ))

  const json = JSON.stringify(props.mappings)
  const copy = () => navigator.clipboard.writeText(json)

  return (
    <div className='dictionary-block'>
      <h2>{props.title} ({mappingElements.length})</h2>
      <button onClick={copy}>Copy</button>
      <button onClick={props.handleSave}>Save</button>
      {mappingElements}
    </div>
  )
}

export default DictionaryView
