import IdleTimer from 'react-idle-timer'
import React, { useState } from 'react'

const DEFAULT_IDLE_TIMEOUT_SECONDS = 4 * 60

export default function InactivityReset (props) {
  const [showResetPopup, setShowResetPopup] = useState(false)

  const resetPopup = (
    <div className='modal'>
      <div className='modal-content'>
        <span>No activity in the last {DEFAULT_IDLE_TIMEOUT_SECONDS} seconds</span>
        <h1>Restart Game?</h1>
        <button
          onClick={() => { props.resetGame(); setShowResetPopup(false) }}
        >
          Yes
        </button>
        <button
          onClick={() => setShowResetPopup(false)}
        >
          No
        </button>
      </div>
    </div>
  )

  return (
    <div>
      <IdleTimer
        element={document}
        onActive={() => console.log('active')}
        onIdle={() => {
          setShowResetPopup(true)
          console.log('idle')
        }}
        onAction={() => console.log('ACTION!')}
        timeout={DEFAULT_IDLE_TIMEOUT_SECONDS * 1000}
      />
      {showResetPopup && resetPopup}
    </div>
  )
}
