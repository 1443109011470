import React from 'react'
import GameText from './GameText'
import Stats from './Stats'
import DictionaryView from './DictionaryView'
// import Verbs from './Verbs'
import MissingWords from './MissingWords'
import YarnUploader from './YarnUploader'
import YarnDownloader from './YarnDownloader'
import _ from 'lodash'
import Meanings from './data/meanings'
import Conversions from './data/conversions'
import Tags from './data/tags'
import SavedWords from './SavedWords'
import Illustration from './Illustration'
import {
  CSSTransition
} from 'react-transition-group'
import InactivityReset from './demoextensions/InactivityReset'
import moment from 'moment'

let _wordCounter = JSON.parse(window.localStorage.getItem('wordCounter')) || 0

function createGuid () {
  function s4 () {
    return Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1)
  }
  return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4()
}

function resetWordCounter () {
  _wordCounter = 0
  return createTextWord('Nothing')
}

function createTextWord (word) {
  window.localStorage.setItem('wordCounter', _wordCounter)
  _wordCounter++
  return { word: word, index: _wordCounter }
}

const InitialState = {
  // game
  gameTexts: JSON.parse(window.localStorage.getItem('gameTexts')) || [[resetWordCounter()]],
  savedWords: JSON.parse(window.localStorage.getItem('savedWords')) || {},
  stats: JSON.parse(window.localStorage.getItem('stats')) || {
    viewedWords: [],
    startTime: moment().format('YYYY-MM-DD_HH:mm:ss')
  },

  // dev
  tag: '',
  filter: '',
  meanings: JSON.parse(window.localStorage.getItem('meanings')) || Meanings,
  conversions: JSON.parse(window.localStorage.getItem('conversions')) || Conversions,
  tags: JSON.parse(window.localStorage.getItem('tags')) || Tags,

  // Yarn
  yarnPositions: JSON.parse(window.localStorage.getItem('positions')) || {},

  loading: false
}

const URL_MEANING = 'https://jsonblob.com/api/jsonBlob/9c3ee6d4-e762-11e9-af4b-1935090fbf1f'
const URL_CONVERSIONS = 'https://jsonblob.com/api/jsonBlob/dd095bc1-e762-11e9-af4b-970fae1ab147'
const URL_TAGS = 'https://jsonblob.com/api/jsonBlob/e9400976-e762-11e9-af4b-59e9ffbff084'
const URL_STATS = 'https://jsonblob.com/api/583ade3e-184e-11ea-a766-755a26347f91'

async function GetJsonFromUrl (url) {
  try {
    console.log('GET', url)
    const response = await fetch(url)
    const json = await response.json()
    console.log('success:', JSON.stringify(json))
    return json
  } catch (error) {
    console.error('error:', error)
    throw error
  }
}

async function PutDataToUrl (url, dataRaw, name) {
  const data = JSON.stringify(dataRaw)
  window.localStorage.setItem(name, data)
  try {
    console.log('PUT', url, data)
    const response = await fetch(url, {
      method: 'PUT', // or 'PUT'
      body: data, // data can be `string` or {object}!
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
        'Data-Type': 'json',
        'Access-Control-Allow-Origin': '*'
      }
    })
    const json = response.headers.get('Location')
    console.log('Success:', json)
  } catch (error) {
    console.error('Error:', error)
  }
}

async function PutStatsToURL (url, stats, id) {
  const data = JSON.stringify(stats)
  try {
    console.log('PUT', `${url}/${stats.startTime || createGuid()}`, data)
    const response = await fetch(url, {
      method: 'PUT', // or 'PUT'
      body: data, // data can be `string` or {object}!
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
        'Data-Type': 'json',
        'Access-Control-Allow-Origin': '*'
      }
    })
    const json = response.headers.get('Location')
    console.log('Success:', json)
  } catch (error) {
    console.error('Error:', error)
  }
}

class Game extends React.Component {
  constructor (props) {
    super(props)

    // Method bindings
    this.changeWord = this.changeWord.bind(this)
    this.handleLoadText = this.handleLoadText.bind(this)
    this.handleStartOver = this.handleStartOver.bind(this)
    this.getWordMeaning = this.getWordMeaning.bind(this)
    this.getWordConversion = this.getWordConversion.bind(this)
    this.getWordCleanup = this.getWordCleanup.bind(this)
    this.wordHasDirectMeaningOrConversion = this.wordHasDirectMeaningOrConversion.bind(this)
    this.updateAll = this.updateAll.bind(this)
    this.changeConfig = this.changeConfig.bind(this)
    this.addConfig = this.addConfig.bind(this)
    this.updateStateWithJson = this.updateStateWithJson.bind(this)
    this.handleTagChange = this.handleTagChange.bind(this)
    this.handleFilterChange = this.handleFilterChange.bind(this)
    this.handleSaveWord = this.handleSaveWord.bind(this)
    this.handleRemoveSavedWord = this.handleRemoveSavedWord.bind(this)
    this.removeDictionaryElement = this.removeDictionaryElement.bind(this)
    this.saveProgress = this.saveProgress.bind(this)
    this.resetAllGameData = this.resetAllGameData.bind(this)

    this.state = InitialState

    const meanP = GetJsonFromUrl(URL_MEANING)
    meanP.then(this.updateStateWithJson('meanings'))

    const convP = GetJsonFromUrl(URL_CONVERSIONS)
    convP.then(this.updateStateWithJson('conversions'))

    const tagsP = GetJsonFromUrl(URL_TAGS)
    tagsP.then(this.updateStateWithJson('tags'))

    Promise
      .all([meanP, tagsP, convP])
      .catch(err => {
        console.log(err)
        this.setState({ loading: false })
      })
      .then(() => this.setState({ loading: false }))
  }

  updateStateWithJson (key) {
    return (v) => {
      const newState = _.cloneDeep(this.state)
      newState[key] = v
      console.log('json update', newState)
      this.setState(newState)
    }
  }

  resetAllGameData () {
    window.localStorage.removeItem('gameTexts')
    window.localStorage.removeItem('savedWords')
    window.localStorage.removeItem('stats')

    // TODO: send info to storage?
    PutStatsToURL(URL_STATS, this.state.stats)

    const resetState = {
      gameTexts: [[resetWordCounter()]],
      savedWords: {},
      stats: {
        viewedWords: [],
        startTime: moment().format('YYYY-MM-DD_HH:mm:ss')
      }
    }

    console.log(' aaaaa')

    this.setState(resetState)
  }

  removeDictionaryElement (collectionName, key) {
    const newState = _.clone(this.state)
    delete newState[collectionName][key]
    console.log('removed element from', collectionName, key, newState)
    this.setState(newState)
  }

  handleSaveWord (word) {
    const newState = _.clone(this.state)
    const meaning = this.getWordMeaning(word)
    const converted = this.getWordConversion(word)

    // Save conversion or meanings
    newState.savedWords[converted] = [converted]
    // newState.savedWords[converted] = !_.isEmpty(meaning)
    // ? meaning.split(' ') : ['meaningless']
    console.log('saved word', word, converted, meaning, newState.savedWords[converted])
    this.setState(newState)

    this.saveProgress()
  }

  saveProgress () {
    window.localStorage.setItem('gameTexts', JSON.stringify(this.state.gameTexts))
    window.localStorage.setItem('stats', JSON.stringify(this.state.stats))
    window.localStorage.setItem('savedWords', JSON.stringify(this.state.savedWords))
  }

  handleRemoveSavedWord (word) {
    const newState = _.clone(this.state)
    const converted = this.getWordConversion(word)
    delete newState.savedWords[converted]
    console.log('removed saved word', word, converted, newState)
    this.setState(newState)
  }

  handleLoadText (words) {
    const newState = _.clone(this.state)
    newState.gameTexts.push(_.map(words, createTextWord))
    console.log('loaded words', words, newState)
    this.setState(newState)

    this.saveProgress()
  }

  wordHasDirectMeaningOrConversion (word) {
    // lowercase + apostrofe
    const cleaned = this.getWordCleanup(word)
    const conversion = this.state.conversions[cleaned]
    if (conversion) return true
    const meaning = this.state.meanings[cleaned]
    if (meaning) return true
    return false
  }

  getWordCleanup (word) {
    return word.toLowerCase().split('\'')[0]
  }

  getWordConversion (word) {
    const cleaned = this.getWordCleanup(word) // lowercase + apostrofe

    // find available conversion
    return (this.state.conversions[cleaned] || cleaned).toLowerCase()
  }

  getWordMeaning (word) {
    const converted = this.getWordConversion(word)
    const meaning =
      this.state.meanings[converted]
    return meaning || null
  }

  changeWord (textIndex) {
    return (wordIndex) => {
      return () => {
        const newState = _.clone(this.state)
        const word = newState.gameTexts[textIndex][wordIndex].word
        const meaning = this.getWordMeaning(word)
        const meaningWordArray = !_.isEmpty(meaning)
          ? meaning.split(' ') : ['meaningless']

        const converted = this.getWordConversion(word)
        newState.stats.viewedWords.push(converted)

        const meaningArray = _.map(meaningWordArray, createTextWord)
        newState.gameTexts[textIndex].splice(wordIndex, 1, ...meaningArray)

        console.log('viewed words', newState.stats.viewedWords)
        this.setState(newState)

        this.saveProgress()
      }
    }
  }

  handleTagChange (e) {
    this.setState({ tag: e.target.value })
  }

  handleFilterChange (e) {
    this.setState({ filter: e.target.value })
  }

  addConfig (configKey) {
    return (itemKey) => {
      return (itemValue) => {
        console.log('AAAAAAAAAAAAAAAAAAAAAAAAAAAA', itemValue)
        const newState = _.cloneDeep(this.state)
        newState[configKey][itemKey] = itemValue || 'meaningless'
        console.log('Added config', configKey, itemKey, newState[configKey][itemKey])
        newState[configKey] =
        _.fromPairs(_.map(
          _.orderBy(_.keys(newState[configKey]), (v, k) => v),
          (key) => [key, newState[configKey][key]]
        ))

        this.setState(newState)
      }
    }
  }

  changeConfig (configKey) {
    return (itemKey) => {
      return (e) => {
        const newState = _.cloneDeep(this.state)
        newState[configKey][itemKey] = e.target.value
        this.setState(newState)
      }
    }
  }

  updateAll (newMeanings, newConversions, newTags, newPositions) {
    this.setState({
      meanings: newMeanings,
      conversions: newConversions,
      tags: newTags,
      yarnPositions: newPositions
    })

    // TODO: save all here and send POST?
    // TODO: use only one url

    window.localStorage.setItem('meanings', JSON.stringify(newMeanings))
    window.localStorage.setItem('conversions', JSON.stringify(newConversions))
    window.localStorage.setItem('tags', JSON.stringify(newTags))
    window.localStorage.setItem('positions', JSON.stringify(newPositions))
  }

  handleStartOver () {
    const newState = _.clone(this.state)
    newState.gameTexts = [[resetWordCounter()]]
    console.log('restarting', newState)
    this.setState(newState)

    this.saveProgress()
  }

  render () {
    if (this.state.loading) return <span className='loading'>loading...</span>
    // const dev = window.location.pathname.indexOf('dev') >= 0
    const isDevMode = window.location.search.indexOf('dev') >= 0
    const isDemoMode = window.location.search.indexOf('demo') >= 0

    function FilterByTagBase (filter, tags) {
      return (list) => {
        if (_.isEmpty(filter)) {
          return list
        }
        return _.fromPairs(
          _.map(_.filter(_.keys(list),
            (v) => tags[v] && (tags[v].toLowerCase().indexOf(filter.toLowerCase()) >= 0)),
          (v) => [v, list[v]]))
      }
    }

    function FilterByFilterBase (filter, tags, meanings) {
      return (list) => {
        if (_.isEmpty(filter)) {
          return list
        }
        return _.fromPairs(
          _.map(_.filter(_.keys(list),
            (v) =>
              (v.toLowerCase().indexOf(filter.toLowerCase()) >= 0) ||
              (meanings[v] && meanings[v].toLowerCase().indexOf(filter.toLowerCase()) >= 0) ||
              (tags[v] && tags[v].toLowerCase().indexOf(filter.toLowerCase()) >= 0)),
          (v) => [v, list[v]]))
      }
    }

    let devThings = null
    if (isDevMode) {
      const allTheWordsCleaned =
    _.fromPairs(
      _.orderBy(
        _.toPairs(
          _.reduce(
            _.flatten(
              _.union(
                _.values(
                  _.mapValues(
                    this.state.meanings,
                    meaning => _.map(meaning.toLowerCase().trim().split(' '), this.getWordCleanup)
                  )
                ),
                _.values(
                  _.mapValues(this.state.conversions, this.getWordCleanup)
                ),
                _.keys(this.state.meanings)
              )),
            (acc, el) => {
              (acc[el] && acc[el]++) || (acc[el] = 1); return acc
            }, {}
          )), x => x[1]))

      const allTheWordsUnconverted =
    _.fromPairs(
      _.orderBy(
        _.toPairs(
          _.reduce(
            _.flatten(
              _.values(
                _.mapValues(
                  this.state.meanings,
                  meaning => _.map(meaning.toLowerCase().trim().split(' '), this.getWordConversion)
                )
              ),
              _.keys(this.state.meanings)
            ),
            (acc, el) => {
              (acc[el] && acc[el]++) || (acc[el] = 1); return acc
            }, {}
          )), x => x[1]))
      const FilterByTag = FilterByTagBase(this.state.tag, this.state.tags)
      const FilterByFilter = FilterByFilterBase(this.state.filter, this.state.tags, this.state.meanings)

      const filterOptions = _.map(_.uniq(_.flatten(
        _.map(
          _.values(this.state.tags),
          v => v.split(' ')))),
      (tag, i) => (
        <option key={i} value={tag}>{tag}</option>
      ))

      console.log('all the words', allTheWordsCleaned)
      console.log('all the words unconverted', allTheWordsUnconverted)

      devThings = (
        <div>
          <hr />
          <div className='flex-container'>
            <YarnUploader updateMeanings={this.updateAll} />
            <YarnDownloader
              allWords={_.keys(allTheWordsCleaned)}
              positions={this.state.yarnPositions}
              meanings={this.state.meanings}
              conversions={this.state.conversions}
              tags={this.state.tags}
            />
          </div>
          <hr />
          <span>
          Tag
            <select type='text' value={this.state.tag} onChange={this.handleTagChange}>
              <option value=''>Clear</option>
              {filterOptions}
            </select>
          </span>
          <span>
          Filter
            <input type='text' value={this.state.filter} onChange={this.handleFilterChange} />
          </span>
          <div className='flex-container horizontal-fit'>
            <MissingWords
              allWords={allTheWordsUnconverted}
              wordHasDirectMeaningOrConversion={this.wordHasDirectMeaningOrConversion}
              addMeaning={this.addConfig('meanings')}
              addConversion={this.addConfig('conversions')}
            />
            <DictionaryView
              title='meanings'
              mappings={FilterByFilter(FilterByTag(this.state.meanings))}
              handleChange={this.changeConfig('meanings')}
              handleSave={() => PutDataToUrl(URL_MEANING, this.state.meanings, 'meanings')}
              handleRemoveElement={(word) => this.removeDictionaryElement('meanings', word)}
              emptyFirst
            />
            <DictionaryView
              title='tags'
              mappings={FilterByFilter(FilterByTag(this.state.tags))}
              handleChange={this.changeConfig('tags')}
              handleSave={() => PutDataToUrl(URL_TAGS, this.state.tags, 'tags')}
              handleRemoveElement={(word) => this.removeDictionaryElement('tags', word)}
            />
            <DictionaryView
              title='conversions'
              mappings={this.state.conversions}
              handleChange={this.changeConfig('conversions')}
              handleSave={() => PutDataToUrl(URL_CONVERSIONS, this.state.conversions, 'conversions')}
              handleRemoveElement={(word) => this.removeDictionaryElement('conversions', word)}
              emptyFirst
            />
          </div>
        </div>
      )
    }
    // <Verbs meanings={this.state.meanings} />

    const gameTextsElements = this.state.gameTexts.map((gameText, i) =>
      <GameText
        currentText={gameText}
        // currentText={gameTexts}
        key={i}
        changeWord={this.changeWord(i)}
        saveWord={(word) => this.handleSaveWord(word)}
        removeSaveWord={(word) => this.handleRemoveSavedWord(word)}
        getWordMeaning={this.getWordMeaning}
        getWordConversion={this.getWordConversion}
        savedWords={this.state.savedWords}
      />)

    const concat = _.map(_.flatten(this.state.gameTexts),
      (wordObj) => ({
        word: this.getWordConversion(wordObj.word),
        index: wordObj.index
      }))

    const gameJustStarted =
      this.state.gameTexts[0].length === 1 &&
      this.state.gameTexts[0][0].word.toLowerCase() === 'nothing'

    const game = (
      <div>
        {/* <div className='overlay' /> */}

        <div className='start-over'>
          <CSSTransition
            in={!gameJustStarted}
            classNames='illustration'
            unmountOnExit
            timeout={400}
          >
            <button className='btn-secondary' onClick={(e) => this.handleStartOver()}>
              Clear Text
            </button>
          </CSSTransition>
        </div>
        <Stats meanings={this.state.meanings} stats={this.state.stats} />
        <div className='game-text'>
          {gameTextsElements}
        </div>
        <SavedWords
          savedWords={this.state.savedWords}
          getWordMeaning={this.getWordMeaning}
          onLoadText={this.handleLoadText}
          onRemoveSavedWord={this.handleRemoveSavedWord}
        />
        {!gameJustStarted && (<hr />)}
        <Illustration words={concat} />
      </div>
    )

    const demoThings = (
      <div>
        <InactivityReset
          resetGame={this.resetAllGameData}
        />
      </div>
    )

    return (
      <div>
        {isDemoMode && demoThings}
        {game}
        {devThings}
      </div>
    )
  }
}

export default Game
