import React, { useState } from 'react'
import _ from 'lodash'

class MissingWord extends React.Component {
  constructor (props) {
    super(props)

    this.state = { editing: false, editingMeaning: false, value: '' }
    this.handleCancel = this.handleCancel.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleAddConversion = this.handleAddConversion.bind(this)
    this.handleAddMeaning = this.handleAddMeaning.bind(this)
  }

  handleAddConversion () {
    this.setState({
      editing: true,
      editingMeaning: false
    })
  }

  handleAddMeaning () {
    this.setState({
      editing: true,
      editingMeaning: true
    })
  }

  handleSubmit (mode) {
    if (this.state.editingMeaning) {
      this.props.addMeaning(this.state.value)
    } else {
      this.props.addConversion(this.state.value)
    }
  }

  handleCancel () {
    this.setState({
      editing: false
    })
  }

  render () {
    return (
      <div>
        <span>{`${this.props.word} (${this.props.count})`}</span>
        {this.state.editing ? (
          <span>
            <input type='text' value={this.state.value} onChange={(e) => this.setState({ value: e.target.value })} />
            <button onClick={this.handleSubmit}>Submit</button>
            <button onClick={this.handleCancel}>Cancel</button>
          </span>
        ) : (
          <span>
            <button onClick={this.handleAddMeaning}>Meaning</button>
            <button onClick={this.handleAddConversion}>Conversion</button>
          </span>
        )}
      </div>
    )
  }
}

function MissingWords (props) {
  var [filterType, setFilterType] = useState(0)
  var [showAll, setShowAll] = useState(false)

  const missingWords =
      _.orderBy(
        _.map(
          _.filter(
            _.keys(props.allWords),
            word => showAll || !props.wordHasDirectMeaningOrConversion(word)),
          x => [x, props.allWords[x]]),
        x => x[filterType], filterType === 0 ? 'asc' : 'desc')

  console.log('Missing words: ', missingWords)

  const missingWordElements = _.map(missingWords, (word) => (
    <MissingWord
      key={`missing_${word[0]}`}
      word={word[0]}
      count={word[1]}
      addMeaning={props.addMeaning(word[0])}
      addConversion={props.addConversion(word[0])}
    />
  ))

  return (
    <div className='dictionary-block'>
      <h2>{showAll ? 'all words' : 'missing'} ({missingWordElements.length})</h2>
      <div>
        <button onClick={() => setFilterType(0)}> A-Z </button>
        <button onClick={() => setFilterType(1)}> Count </button>
        {
          showAll
            ? (
              <button onClick={() => setShowAll(false)}> Show Missing </button>
            ) : (
              <button onClick={() => setShowAll(true)}> Show All </button>
            )
        }
      </div>
      {missingWordElements}
    </div>
  )
}

export default MissingWords
