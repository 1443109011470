import React from 'react'
import _ from 'lodash'

function Stats (props) {
  const totalMeanings = _.keys(props.meanings).length
  const foundMeanings = _.uniq(props.stats.viewedWords).length
  const percentage = Math.floor((foundMeanings / totalMeanings) * 100)

  return (
    <div>
      <div className='progress-meanings'>
        <span style={{ width: `${percentage}%` }}>
          <span className='progress-written' style={{ float: 'right' }}>{percentage}%</span>
        </span>
      </div>
    </div>
  )
}

export default Stats
