import React from 'react'
import './App.css'
import Game from './Game'

function App () {
  return (
    <div>
      <Game />
    </div>
  )
}

export default App
